// 登录
const LoginApi="/user/1.0/Login";
// 个人信息
const userInfo = "/store/1.0/ConsumptionArea/Self";
// 欢迎页查手牌
const  examineKeyCard= "/receptionkeycard/1.0/Reception/SelectRegisteredAsync"
// 券核销
const ticket = "/guest/1.0/ConsumptionPackage/coupons"
// 券核销
const ticket2 = "/guest/2.0/ConsumptionPackage/coupons"
// 支付
const pay = "/guest/1.0/Pay"
// 调单
const transferOrder = "/guest/1.0/Order"
// 支付方式
const payMent = "/store/1.0/PaymentMethod"
// 查单个手牌号
const singlebycode = "/receptionkeycard/1.0/Reception/singlebycode"
// 组合
const group = "/guest/1.0/ConsumptionSubjectRelation/details"
// 打单
const print = "/guest/1.0/Order/print"
// 反打单
const unprint = "/guest/1.0/Order/unprint"
// 打印
const PrintReport = "/report/1.0/Report"
// 获取小程序二维码
const qrcode = "/member/1.0/WeChatMini/qrcode"
// 手牌组合拆分
const remove = "/guest/1.0/ConsumptionSubjectRelation/details"
// 获取轮播图
const getStoreSetting = "/store/1.0/StoreSetting"
const getSettingCode = '/store/1.0/Setting'
const ReportFormat = '/report/1.0/ReportFormat'
// /api/{v}/Reception/ManualOpen
const ManualOpen = '/receptionkeycard/1.0/Reception/ManualOpen'
// 买单不离场（重新登记手牌）
const rereception = '/receptionkeycard/1.0/Reception/rereception'
// POST /api/{v}/Order/NotLeave 买单不离场（包含结账）
const NotLeave = '/guest/1.0/Order/NotLeave'
// /api/{v}/MemberCard/QrCode
const QrCode = '/member/1.0/MemberCard/QrCode'
// /api/{v}/MemberCard  查询会员卡信息
const MemberCard = '/member/1.0/MemberCard'
// /api/{v}/Trasaction/Extend/Check   会员卡支付
const MemberCheck = '/member/2.0/Trasaction/Extend/Check'
// 获取客人类型
const GuestType = '/store/1.0/GuestType'
// 获取版本环境
const getSelfVersion = '/tenant/1.0/VIP/SelfVersion'


export default {
	LoginApi,
	userInfo,
	examineKeyCard,
	ticket,
	pay,
	transferOrder,
	payMent,
	singlebycode,
	group,
	print,
	unprint,
	PrintReport,
	qrcode,
	getStoreSetting,
	ticket2,
	getSettingCode,
	ReportFormat,
	ManualOpen,
	remove,
	rereception,
	QrCode,
	MemberCard,
	GuestType,
	NotLeave,
	MemberCheck,
	getSelfVersion
}
